import React from 'react';
import { Link } from 'react-router-dom';
import ProjectList from './ProjectList';

function Projects() {
  return (
    <div className='mx-10'>
      <h1 className='mt-0  text-center pt-10'>My Projects</h1>
      <ProjectList bool={false}/>
      <Link to="/" className="hover:underline px-2 text-[#f7931E] justify-center flex">← back</Link>
    </div>
  );
}

export default Projects;
import React from 'react';

const ItemComponent = ({ item, onClick }) => {
  return (
    <div
      key={item.id}
      onClick={() => onClick(item.id)}
      className='flex mb-6'
    >
      <div className='flex-shrink-0 w-24 h-24 md:w-48 md:h-36'> {/* 画像部分をレスポンシブに */}
        <img
          src={item.eyecatch.url}
          alt={item.title}
          className='w-full h-full object-cover rounded-lg'
        />
      </div>
      <div className='ml-4 flex flex-col justify-start text-left '> {/* テキスト部分も可変に */}
        <h3 className='font-semibold'>{item.title}</h3>
        <div className='mt-auto'>
          <p className='text-sm text-gray-200'>{item.date}</p>
          <p className='text-sm text-gray-200'>{item.community}</p>
        </div>
      </div>
    </div>
  );
};

export default ItemComponent;

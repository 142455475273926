import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ItemComponent from './ItemComponent';

const ProjectList = ({ bool }) => {
  const navigate = useNavigate();
  const [apiData, setApiData] = useState({ contents: [] });
  const [isLoading, setIsLoading] = useState(true); // ローディング状態を追加

  useEffect(() => {
    fetch('https://yutoasano.microcms.io/api/v1/blogs?fields=id,createdAt,updatedAt,publishedAt,revisedAt,title,eyecatch,community,date,top', {
      headers: {
        'X-API-KEY': 'HOSfyb6zeMl03QQe1jsc90XQryzVlYufgrNf'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        const filteredData = bool ? data.contents.filter(item => item.top) : data.contents;
        setApiData({ contents: filteredData });
        setIsLoading(false); // データ取得完了時にローディングを解除
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false); // エラー発生時もローディングを解除
      });
  }, [bool]);

  const handleClick = (id) => {
    navigate(`/projects/${id}`);
  };

  return (
    <div className='md:w-1/3 mx-auto'>
      {isLoading ? (
        <p>loading...</p> // ローディングメッセージを表示
      ) : (
        apiData.contents.map((item) => (
          <ItemComponent key={item.id} item={item} onClick={handleClick} />
        ))
      )}
    </div>
  );
};

export default ProjectList;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { createPortal } from 'react-dom';
import ProjectList, {ProjectComponent} from './ProjectList';

function Home() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="text-center py-20 px-10">
      <div className="w-full md:w-1/3 mx-auto">
        <h1 className="text-2xl md:text-4xl font-black leading-tight">Yuto Asano</h1>
        <h1 className="text-xl md:text-2xl font-black mb-7">浅野 悠人</h1>
        <div className="flex justify-center">
          <img
            className=""
            src={`${process.env.PUBLIC_URL}/images/ya.svg`}
            alt="Example Image"
          />
        </div>
        <div className="mt-10">
          <ul className="list-none text-center">
            <li>2001年生</li>
            <li>慶應義塾大学 環境情報学部 卒業</li>
            <li>筑波大学大学院 情報学学位プログラム</li>
            <li>博士前期課程 在籍</li>
          </ul>
        </div>
        <div className="mt-3">
          <Link to="/bio" className="hover:underline px-2 text-[#f7931E]">
            More →
          </Link>
        </div>
      </div>
      <div className="mt-8">
      <h2 className="text-lg mb-0">Motivation:</h2>
      <h1 className="mt-0 text-xl mb-2">空間設計時代の体験創造支援</h1>
      <div className="flex justify-center">
        <img
          className="cursor-pointer w-full md:w-1/3 md:mx-auto"
          src={`${process.env.PUBLIC_URL}/images/idea.svg`}
          alt="Example Image"
          onClick={handleImageClick}
        />
      </div>
      <div className="text-wrap">
        <p className="text-sm md:w-1/3 md:mx-auto md:text-ellipsis md:overflow-hidden text-left pt-5">
          Vision ProなどのVR・AR機器の進化や、3DモデルすらもAIで生成ができるようになった現代は、個人が空間を自習に設計し、それを再生し、また別の個人に空間を通した体験を届けられる時代だと考えられます。この時代感を元に、どのような情報・社会的システムがあればこの体験設計を手助けし、魅了ある空間を人に届けられるのかを様々な角度から探求しています。
        </p>
      </div>
      <div className="mt-3">
          <Link to="/vision" className="hover:underline px-2 text-[#f7931E]">
            More →
          </Link>
        </div>
    </div>

      {isModalOpen &&
        createPortal(
          <div
            className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center"
            onClick={handleCloseModal}
          >
            <img
              className="w-[80vw] h-auto object-contain"
              src={`${process.env.PUBLIC_URL}/images/idea.svg`}
              alt="Example Image"
              onClick={(e) => e.stopPropagation()}
            />
          </div>,
          document.body
        )}

      <div className="mt-8">
            <h2 className="text-lg mb-0">Projects:</h2>
            <h1 className="mt-0 text-xl mb-2">場を、世界を、空間を創る</h1>
            <div className="text-wrap">
              <p className="text-sm md:w-1/3 md:mx-auto md:text-ellipsis md:overflow-hidden text-left pt-5">
                学部入学以降、空間のデザインやコミュニティの作成・紹介など様々なプロジェクトに身を置いてきました。プロジェクトページではより多くのプロジェクトを紹介しています。
              </p>

            </div>
    
            <ProjectList bool={true}/>
      <div className="mt-3">
          <Link to="/projects" className="hover:underline px-2 text-[#f7931E]">
            More →
          </Link>
      </div>
      </div>

      <div className="mt-8">
            <h2 className="text-lg mb-0">Contact:</h2>
            <h1 className="mt-0 text-xl mb-2">各種SNS・連絡先</h1>
            <div className="text-wrap">
    
            </div>
    
      <div className="mt-3">
          <Link to="/contact" className="hover:underline px-2 text-[#f7931E]">
            More →
          </Link>
      </div>
      </div>



    </div>
  );
}

export default Home;
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import MoreComponent from './pages/MoreComponent';
import Projects from './pages/Projects';
import ProjectDetail from './pages/ProjectDetail';

function App() {
  return (
    <Router>
      <div className="bg-[#181818] text-white min-h-screen flex-col items-center justify-center font-notosansjp">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/bio" element={<MoreComponent endpoint="fix" contentId="0v2la86os" />} />
          <Route path="/vision" element={<MoreComponent endpoint="fix" contentId="ldmzxu6qt" />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/projects/:id" element={<MoreComponent endpoint="blogs"/>}/>
          <Route path="/contact" element={<MoreComponent endpoint="fix" contentId="k-oxhf4bed5"/>}/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import { createClient } from 'microcms-js-sdk';
import { useParams, Link } from 'react-router-dom';
import '../styles.css';

const client = createClient({
  serviceDomain: 'yutoasano', // サービスドメイン
  apiKey: 'HOSfyb6zeMl03QQe1jsc90XQryzVlYufgrNf', // APIキー
});

function ProjectDetail({endpoint}) {
  const { id } = useParams(); // URL パラメータから id を取得
  const [data, setData] = useState(null);

  useEffect(() => {
    client
      .get({ endpoint: endpoint, contentId: id })
      .then(response => {
        setData(response);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [id]);

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div className="pt-10 px-5 pb-10 max-w-2xl justify-center mx-auto">
      <h1 className='text-[32px] font-black leading-[30px] text-center'>{data.title}</h1>
      <div className="mt-10 flex justify-center text-sm sm:text-base">
        <div dangerouslySetInnerHTML={{ __html: data.body }}></div>
      </div>
      <Link to="/projects" className="hover:underline px-2 text-[#f7931E] justify-center flex">← back</Link>
    </div>
  );
}

export default ProjectDetail;
